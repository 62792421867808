@import url("https://fonts.googleapis.com/css?family=Heebo:100,200,300,400,500,700");

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: white;
}

.vcenter {
  vertical-align: middle;
}

.pad-left-3 {
  padding-left: 3px !important;
}

.pad-right-3 {
  padding-right: 3px !important;
}

.pad-left-5 {
  padding-left: 5px !important;
}

.pad-right-5 {
  padding-right: 5px !important;
}

.pad-left-10 {
  padding-left: 10px !important;
}

.pad-right-10 {
  padding-right: 10px !important;
}

.pad-left-15 {
  padding-left: 15px !important;
}

.pad-right-15 {
  padding-right: 15px !important;
}

.pad-left-20 {
  padding-left: 20px !important;
}

.pad-right-20 {
  padding-right: 20px !important;
}

.pad-top-3 {
  padding-top: 3px !important;
}

.pad-top-5 {
  padding-top: 5px !important;
}

.pad-top-10 {
  padding-top: 10px !important;
}

.pad-top-15 {
  padding-top: 15px !important;
}

.pad-top-20 {
  padding-top: 20px !important;
}

.pad-top-25 {
  padding-top: 25px !important;
}

.pad-bottom-5 {
  padding-bottom: 5px !important;
}

.pad-bottom-10 {
  padding-bottom: 10px !important;
}

.pad-bottom-15 {
  padding-bottom: 15px !important;
}

.pad-bottom-20 {
  padding-bottom: 20px !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-left-5 {
  margin-left: 5px !important;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.margin-left-15 {
  margin-left: 15px !important;
}

.margin-left-20 {
  margin-left: 20px !important;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.margin-right-15 {
  margin-right: 15px !important;
}

.margin-right-20 {
  margin-right: 20px !important;
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: auto;
}

.flex-column {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex-shrink: 0;
}

.right {
  justify-content: flex-end;
}

.stretch {
  align-content: stretch;
}

.spread {
  justify-content: space-between;
}

.start {
  align-items: flex-start;
}

.center {
  justify-content: center;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.three-quarter-width {
  width: 75%;
}

.half-width {
  width: 50%;
}

.quarter-width {
  width: 25%;
}

.one-third-width {
  width: 33.3%;
}

.two-thirds-width {
  width: 66.6%;
}

.top-align {
  align-items: flex-start;
  align-content: flex-start;
}

.center-align {
  align-items: center;
}

.wrap {
  flex-wrap: wrap;
}

.pad-left-20 {
  padding-left: 20px;
}

.center-text {
  text-align: center;
}

.padded {
  padding-left: 16px;
  padding-right: 16px;
}

.bordered {
  border-left: 1px #858585 solid;
  border-right: 1px #858585 solid;
}

.align-baseline {
  align-items: baseline;
}

.flex-center-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nowrap-row {
  overflow-x: visible;
  overflow-y: hidden;
  white-space: nowrap;
  width: auto;
}

.nowrap-scroll {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  width: auto;
}

.flex-column {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex-shrink: 0;
}

.bottom {
  justify-content: flex-end;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: #34353d;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #2b2c33;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #747781;
}

